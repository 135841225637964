import find from "lodash/find";
import range from "lodash/range";
import orderBy from "lodash/orderBy";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useMemo } from "react";
import useSWR from "swr";
import { SeatsIOSeat, SortTypes } from "~components/reservation/constants";
import {
  BaseContext,
  ReservationActionTypes,
  ReservationContext,
} from "~context";
import {
  CreateOrderInput,
  LineItemInput,
  LineItemType,
  OrderType,
  TicketDetailsForBackOfficeOrdersQuery,
  UserQuery,
  VenueSeating,
} from "~graphql/sdk";
import { useAccount } from "~hooks/useAccount";
import { useReCaptchaSDK } from "~hooks/useReCaptcha";
import { sdk } from "~lib";
import { getCategoryDataFromLabel, showToast } from "~lib/helpers";
import { getUrl } from "../../lib/helpers/getUrl";
import { useSeatedMultibuy } from "../multibuy/seated";
import * as Sentry from "@sentry/nextjs";
import { useZoneFilters } from "~hooks/reservation/useZoneFilters";
import { usePricing } from "~hooks/reservation/usePricing";
import { useChart } from "~hooks/reservation/useChart";
import { useCreateOrder } from "./useCreateOrder";

export type CreateOrderFn = (
  items: any[],
  addonItems?: any[],
  multiBuyId?: string,
  errorCallback?: (errorMessage: string) => void
) => Promise<boolean>;

const getEvent = async (_: string, orgId: string, eventId: string) =>
  sdk({ orgId })
    .event({ id: eventId })
    .then(({ event }) => event);

const getMembership = async (_: string, orgId: string, membershipId: string) =>
  sdk({ orgId })
    .membership({ id: membershipId })
    .then(({ membership }) => membership);

const getReleaseBySlug = async (
  _: string,
  orgId: string,
  eventId: string,
  slug: string = null,
  activeOnly?: boolean
) =>
  sdk({ orgId })
    .getReleaseBySlug({ slug, eventId, activeOnly })
    .then(({ getReleaseBySlug }) => getReleaseBySlug);

const getReleaseById = async (_: string, orgId: string, id: string) =>
  sdk({ orgId })
    .getReleaseById({ id })
    .then(({ release }) => release);

const getTickets = async (_: string, orgId: string, eventId: string) =>
  sdk({ orgId }).myEventTickets({ id: eventId });

const getMembershipTickets = async (_: string, orgId: string) =>
  sdk({ orgId }).myMembershipTickets();

const getTicketsByIds = async (_: string, orgId: string, ids: string) =>
  sdk({ orgId }).getTicketsByIds({ ids });

const seatsToLineItems = (
  seats: SeatsIOSeat[],
  type: "event" | "membership" | "release",
  oldTickets?: string[]
): LineItemInput[] => {
  getCategoryDataFromLabel;
  return seats.map(({ label, ticketType, category, objectType }, key) => {
    const { zone, section, tags } = getCategoryDataFromLabel(category.label);

    if (!ticketType?.value) {
      Sentry.captureException(new Error(`Ticket type is missing for seat`), {
        tags: {
          orderType: type,
          seatLabel: label,
          seatZone: zone,
          seatSection: section,
        },
        extra: {
          rawSeats: seats,
        },
      });
    }

    const lineItem =
      type === "membership"
        ? {
            type: LineItemType.Membership,
            membershipTypeId: ticketType?.value,
            quantity: 1,
            seatLabel: label,
            seatZone: zone,
            seatSection: section,
            seatTags: tags,
            seatType: objectType === "Seat" ? "seat" : "generalAdmission",
            ...(oldTickets?.[key] ? { previousTicketId: oldTickets[key] } : {}),
          }
        : {
            type: LineItemType.Ticket,
            ticketTypeId: ticketType?.value,
            quantity: 1,
            seatLabel: label,
            seatZone: zone,
            seatSection: section,
            seatTags: tags,
            seatType: objectType === "Seat" ? "seat" : "generalAdmission",
            ...(oldTickets?.[key] ? { previousTicketId: oldTickets[key] } : {}),
          };

    return lineItem;
  });
};

const getUser = async (
  _: string,
  orgId: string,
  userId: string
): Promise<UserQuery["user"]> =>
  sdk({ orgId })
    .user({ id: userId })
    .then(({ user }) => user);

const getBookedSeatsData = async (
  _: string,
  orgId: string,
  id: string,
  type: "event" | "membership"
): Promise<
  TicketDetailsForBackOfficeOrdersQuery["ticketDetailsForBackOfficeOrders"]
> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  sdk({ orgId })
    .ticketDetailsForBackOfficeOrders({
      ...(type !== "membership" && { eventId: id }),
      ...(type === "membership" && { membershipId: id }),
    })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .then((res) => JSON.parse(res.ticketDetailsForBackOfficeOrders));

export const useReservation = (
  type: "event" | "membership" | "release",
  id: string,
  releaseActiveOnly?: boolean
) => {
  const router = useRouter();
  const { organization } = useContext(BaseContext);
  const { isAdmin, isPOS } = useAccount();
  const { recaptchaSdkGenerator } = useReCaptchaSDK();
  const { createOrderAndWaitForCompletion } = useCreateOrder();

  const { zones, seats, addons, holdToken, options, dispatch } = useContext(
    ReservationContext
  );

  const { error: eventError, data: event } = useSWR(
    type === "event" ? ["event", organization?.id, id] : null,
    getEvent
  );

  const { error: membershipError, data: membership } = useSWR(
    type === "membership" ? ["membership", organization?.id, id] : null,
    getMembership
  );

  //memberships dont have releases and shouldnt be firing
  const { error: releaseError, data: release } = useSWR(
    type === "membership"
      ? null
      : router?.query?.release
      ? ["release", organization?.id, router?.query?.release]
      : ["release", organization?.id, id, null, releaseActiveOnly],
    router?.query?.release ? getReleaseById : getReleaseBySlug
  );

  const { data: user } = useSWR(
    isAdmin && router.query.customer
      ? ["user-order", organization?.id, router.query.customer]
      : null,
    getUser
  );

  const memoizedPosUrl = useMemo(() => {
    const posId = [].concat(router?.query?.posId)?.[0] as string | undefined;
    return posId ? `${getUrl(organization?.slug)}/pos/${posId}` : undefined;
  }, [router?.query?.posId]);

  const isNonSeated =
    (type === "event"
      ? event?.venue?.seating
      : membership
      ? membership?.venue?.seating
      : undefined) !== VenueSeating.Seated;

  const setToken = (token: string) =>
    dispatch({ type: ReservationActionTypes.SET_TOKEN, payload: token });

  const updateOptions = (
    key:
      | "amount"
      | "seatSelectType"
      | "zoneType"
      | "coverType"
      | "selectedSort"
      | "selectedZone"
      | "selectedSection"
      | "validSeatSelection",
    value: string | number | boolean
  ) =>
    dispatch({
      type: ReservationActionTypes.UPDATE_OPTIONS,
      payload: { [key]: value },
    });

  const setIsValiSeatSelection = (value: boolean) => {
    updateOptions("validSeatSelection", value);
  };

  const isChangingSeats = !!router?.query?.ownedTickets;

  const { data: ownedTicketsData, error: ownedTicketsError } = useSWR(
    organization?.id && router?.query?.ownedTickets
      ? ["tickets", organization?.id, id]
      : null,
    getTickets
  );
  const {
    data: ownedMembershipTicketsData,
    error: ownedMembershipTicketsError,
  } = useSWR(
    organization?.id && router?.query?.ownedTickets
      ? ["membershipTickets", organization?.id]
      : null,
    getMembershipTickets
  );
  const { data: ownedTicketData, error: ownedTicketError } = useSWR(
    organization?.id && router?.query?.ownedTickets
      ? [
          "ownedTickets",
          organization?.id,
          Array.isArray(router?.query?.ownedTickets)
            ? router?.query?.ownedTickets.join(",")
            : router?.query?.ownedTickets,
        ]
      : null,
    getTicketsByIds
  );

  const { activePromotions } = useSeatedMultibuy(
    event?.multiBuyPromotions ||
      membership?.multiBuyPromotions ||
      release?.event?.multiBuyPromotions,
    seats
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const changeTickets = useMemo(() => {
    let tix = [];

    if (type === "event") {
      tix =
        ownedTicketsData?.myEventTickets?.length > 0
          ? ownedTicketsData?.myEventTickets
          : ownedTicketData?.getTicketsByIds;
    } else {
      tix =
        ownedMembershipTicketsData?.myMembershipTickets.length > 0
          ? ownedMembershipTicketsData?.myMembershipTickets
          : ownedTicketData?.getTicketsByIds;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Array.isArray(tix)
      ? tix?.filter(({ id }) =>
          Array.isArray(router?.query?.ownedTickets)
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              router?.query?.ownedTickets?.includes(id)
            : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              router?.query?.ownedTickets.split(",").includes(id)
        )
      : [];
  }, [
    router?.query?.ownedTickets,
    ownedTicketsData,
    ownedMembershipTicketsData,
    ownedTicketData,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const priceComp = isChangingSeats
    ? changeTickets?.reduce(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        (acc, { lineItem: { price, quantity } }) => (acc += price * quantity),
        0
      )
    : undefined;

  useEffect(() => {
    if (changeTickets?.length) {
      updateOptions("amount", changeTickets.length);
    }
  }, [changeTickets]);

  useEffect(() => {
    if (router?.query?.posId) {
      if (localStorage.getItem("posId")) {
        localStorage.removeItem("posId");
        localStorage.setItem("posId", router?.query?.posId as string);
      } else {
        localStorage.setItem("posId", router?.query?.posId as string);
      }
    }
  }, [router?.query?.posId]);

  const releasedZones = useMemo(
    () =>
      type === "event" ? release?.releaseZones : membership?.membershipZones,
    [type, membership?.membershipZones, release?.releaseZones]
  );

  useEffect(() => {
    if (release && !release?.isActive && !isAdmin) {
      showToast("This is release is not active yet", "error");
      void router.replace("/");
    }
  }, [release]);

  const { filteredZones } = useZoneFilters({
    zones,
    releasedZones,
    opts: {
      coverType: options?.coverType,
      alcoholFreeZone: options?.zoneType,
    },
  });

  const nonSeatedFilteredZone = useMemo(() => {
    return releasedZones?.map((releasedZone) => releasedZone.zone);
  }, [releasedZones]);

  const { pricedZones, getPrice } = usePricing({
    filteredZones: isNonSeated ? nonSeatedFilteredZone : filteredZones,
    releasedZones,
    type,
    isAdmin,
    activePromotions,
  });

  // best-available: filter by the max price of price range, display from most expensive to least expensive
  // price: filter by the min price of price range, display from least expensive to most expensive
  const sortedZones = orderBy(
    pricedZones,
    options.selectedSort === SortTypes.PRICE
      ? "priceRange.min"
      : "priceRange.max",
    options.selectedSort === SortTypes.PRICE ? "asc" : "desc"
  );

  const onSelectCategoryLabel = useCallback(
    (categoryLabel: string) => {
      if (!categoryLabel || !pricedZones) {
        return;
      }

      const newOptions: any = {
        selectBestOnChange: false,
      };
      const {
        zone: zoneLabel,
        section: sectionLabel,
      } = getCategoryDataFromLabel(categoryLabel);

      const zone = find(pricedZones, { name: zoneLabel });

      if (zone) {
        newOptions.selectedZone = zone;

        if (zone?.sections?.length > 0) {
          newOptions.selectedSection = find(zone.sections, {
            name: sectionLabel,
          });
        }

        dispatch({
          type: ReservationActionTypes.UPDATE_OPTIONS,
          payload: newOptions,
        });
      }
    },
    [pricedZones]
  );

  const { data: tooltipData, error: tooltipDataError } = useSWR(
    isAdmin && (event?.id || membership?.id)
      ? [
          "tooltipData",
          organization?.id,
          event?.id || membership?.id,
          membership?.id ? "membership" : "event",
        ]
      : null,
    getBookedSeatsData
  );

  const { chart } = useChart({
    ...(type === "event"
      ? { seatsEventKey: event?.seatsEventKey }
      : type === "membership"
      ? { seatsEventKeys: membership?.events?.map((e) => e?.seatsEventKey) }
      : { seatsEventKey: release?.event?.seatsEventKey }),
    ...(type === "event"
      ? { venue: event?.venue }
      : type === "membership"
      ? { venue: membership?.venue }
      : { venue: release?.event?.venue }),
    amount: options?.amount,
    seatSelectType: options?.seatSelectType,
    currentSeats: seats,
    currentSection: options?.selectedSection,
    currentZone: options?.selectedZone,
    selectBestOnChange: options?.selectBestOnChange,
    selectableZones: pricedZones,
    holdToken,
    type,
    onSelectCategoryLabel,
    setHoldToken: setToken,
    changeTickets,
    tooltipData,
    tooltipDataLoading: isAdmin && !tooltipData && !tooltipDataError,
    validSeatSelection: options?.validSeatSelection,
    setValidSelection: setIsValiSeatSelection,
  });

  useEffect(() => {
    if (router?.query && router.query?.session !== "continue") {
      dispatch({ type: ReservationActionTypes.RESET_OPTIONS });
    }
  }, [router?.query?.session]);

  const handleOrderCreation = async (
    errorCallback?: (errorMessage: string) => void
  ): Promise<boolean> => {
    if (seats && holdToken) {
      let orderType = Object.values(OrderType).includes(
        router.query?.type as OrderType
      )
        ? (router.query?.type as OrderType)
        : undefined;

      if (changeTickets?.length && !router?.query?.type) {
        orderType = OrderType.ChangeSeats;
      }

      const releasePasswordId =
        sessionStorage.getItem("releasePasswordId") || undefined;

      const availableAddons =
        membership?.addons ??
        release?.releaseEventAddons.map(({ eventAddon }) => eventAddon);

      const input: CreateOrderInput = {
        lineItems: [
          ...seatsToLineItems(
            seats,
            type,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            changeTickets?.map((changeTicket) => changeTicket.id) as string[]
          ),
          ...(availableAddons
            ?.map((addon: { isActive: boolean; id: string | number }) => {
              if (addon.isActive && addons?.[addon.id]?.value) {
                return {
                  type: LineItemType.Addon,
                  addonId: addon.id as string,
                  quantity: addons?.[addon.id]?.value,
                };
              }

              return undefined;
            })
            ?.filter((a) => !!a) ?? undefined),
        ],
        holdToken,
        orderType,
        ...(type === "event"
          ? {
              releaseId: release?.id,
            }
          : type === "membership"
          ? {
              membershipId: release?.id ?? membership?.id,
            }
          : { releaseId: release?.id }),
        ...(releasePasswordId && { releasePasswordId }),
        ...(activePromotions?.[0]?.promotion?.id && {
          multiBuyId: activePromotions?.[0]?.promotion?.id,
        }),
      };

      return await createOrderAndWaitForCompletion(input, errorCallback);
    }

    showToast("No seat selection found", "error");
    return false;
  };

  const handleGAOrderCreation: CreateOrderFn = async (
    items,
    addonItems,
    multiBuyId,
    errorCallback
  ) => {
    const orderType = Object.values(OrderType).includes(
      router.query?.type as OrderType
    )
      ? (router.query?.type as OrderType)
      : undefined;

    const addonLineItems =
      addonItems?.map((addon) => ({
        quantity: addon.quantity,
        type: LineItemType.Addon,
        addonId: addon.id,
      })) || [];

    const input = {
      lineItems: [
        ...items.map((ticket) => ({
          quantity: ticket.quantity,
          ...((type === "event" || type === "release") && {
            ticketTypeId: ticket.id,
            type: LineItemType.Ticket,
          }),
          ...(type === "membership" && {
            membershipTypeId: ticket.id,
            type: LineItemType.Membership,
          }),
          seatZone: ticket.zoneId,
        })),
        ...addonLineItems,
      ],
      multiBuyId,
      orderType,
      ...(type === "event"
        ? {
            releaseId: release?.id,
          }
        : type === "membership"
        ? {
            membershipId: release?.id ?? membership?.id,
          }
        : {
            releaseId: release?.id,
          }),
    };

    return await createOrderAndWaitForCompletion(input, errorCallback);
  };

  const minSeatAmountOption = release ? release.minPurchaseQuantity : 1;
  let maxSeatAmountOption = release ? release.maxPurchaseQuantity : 10;

  if (isAdmin || isPOS) {
    maxSeatAmountOption = 100;
  }

  const seatAmountOptions = range(
    Math.max(minSeatAmountOption - 1, 0),
    Math.min(maxSeatAmountOption, 100)
  ).map((i) => ({
    value: i + 1,
    label: `${i === 0 ? `${i + 1} person` : `${i + 1} people`}`,
  }));

  return {
    changeTickets,
    chart,
    dispatch,
    error:
      type === "event"
        ? eventError || ownedTicketsError || ownedTicketError
        : membershipError || ownedMembershipTicketsError || ownedTicketError,
    event,
    getPrice,
    handleGAOrderCreation,
    handleOrderCreation,
    holdToken,
    membership,
    isLoadingZones: !zones,
    isLoading:
      type === "event"
        ? !event &&
          !eventError &&
          (!isChangingSeats ||
            (type === "event"
              ? ownedTicketsData && !ownedTicketsError
              : ownedMembershipTicketsData && !ownedMembershipTicketsError))
        : type === "membership"
        ? !membership && !membershipError
        : !release && !releaseError,
    options,
    release,
    seats,
    selectableZones: sortedZones,
    setToken,
    updateOptions,
    user,
    priceComp,
    memoizedPosUrl,
    seatAmountOptions,
  };
};
